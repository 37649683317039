/*
  Use SASS variables & syntax ($var) for stylesheets in the /assets folder.
  Use CSS variables & syntax (--var()) for styled-components and React components.
*/

$primary-blue: #015798;
$primary-black: #242021;
$primary-grey: rgba(209, 214, 220, 0.5);

$secondary-blue: #0E86C3;
$secondary-grey: #858585;

$tint-blue: rgba(14, 134, 195, 0.2);
$dark-grey: #333333;
$background-grey: #F3F3F4;

$bd-danger: #FD552D;
$bd-info: #6EDFE2;
$bd-success: #00CB81;

$primary-gradient: linear-gradient(64.37deg, $primary-blue 0%, $secondary-blue 83.21%);

$primary-font-size: 0.875rem;

:root {
  --primary-blue: #015798;
  --primary-black: #242021;
  --primary-grey: rgba(209, 214, 220, 0.5);
  --secondary-blue: #0E86C3;
  --secondary-grey: #858585;
  --bd-danger: #FD552D;
  --bd-info: #6EDFE2;
  --bd-success: #00CB81;
  --dark-grey: #333333;
}
