.sidebar-wrapper.ant-layout-sider {
  height: 100%;
  position: relative;
  background: transparent;
  margin: 6px;
}
.sidebar {
  text-align: left;
  height: inherit;
  min-height: calc(100vh - 18px);
  margin-top: 6px;
  box-shadow: 8px 0 30px rgba(1, 87, 152, 0.25);
  border-radius: 6px;
  .nav-pills .nav-link {
    text-transform: uppercase;
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    svg {
      margin-right: 12px;
    }
  }
  .nav-item {
    button {
      text-transform: uppercase;
    }
    &.border-bottom {
      border-bottom: 1px solid #D1D6DC;
    }
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: white;
    color: var(--primary-blue);
  }
  .navbar-brand {
    margin-bottom: 1em;
  }
}
