@import './variables';
@import './sidebar';

p {
  font-weight: normal;
}

.main-title {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  //line-height: 35px;
  color: $primary-black;
}

.smaller-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: $primary-black;
}

span.text-primary {
  color: $primary-blue !important;
}

.success {
  color: $bd-success !important;
}
.danger {
  color: $bd-danger !important;
}

.bd-text {
  color: $primary-blue !important;
}

.bg-primary {
  background: $primary-gradient;
}
.bg-primary-shadow {
  background: $primary-blue;
  box-shadow: 0 0 30px rgba(1, 87, 152, 0.25);
  border-radius: 6px;
}

.sidebar-wrapper {
  position: relative;
  height: inherit;
  .sidebar-footer {
    position: absolute;
    bottom: 24px;
    left: 4rem;
  }
}

.ant-layout-header {
  text-align: left;
  color: white !important;
  display: block;
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
  h3 {
    color: white;
  }
}
.ant-table {
  table {
    thead tr th {
      color: $primary-blue;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}
.tenants-table {
  .ant-table {
    table {
      table-layout: fixed !important;
    }
  }
}
.alerts-grid {
  .ant-table {
    table {
      table-layout: fixed !important;
      thead {
        tr {
          th {
            &:first-child {
              width: 5%;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
